import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning-birtan'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, trainees) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: vm.getOfficeName(data.office_id), style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: data.circular_memo_no, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' }
          ]
        ]
        if (Store.state.Auth.activeRoleId === 1 || Store.state.Auth.authUser.org_id === 1) {
          bodySeperate.push([
            { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
            {},
            {},
            {}
          ]
          )
        }
        bodySeperate.push([
          { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.office_type_bn : data.office_type, style: 'td', alignment: 'left' }
        ]
        )
        bodySeperate.push(
          [
            { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.office_bn : data.office, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
          ]
          )
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' }
          ]
          )
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['18%', '3%', '29%', '18%', '3%', '29%'],
              body: [
                [
                  { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: data.circular_memo_no, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.office_type_bn : data.office_type, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.office_bn : data.office, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
      pdfContent.push({ text: vm.$t('globalTrans.details'), style: 'hh', alignment: 'center' })
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_tim.number_of_class'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_tim.allowance'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.total_amount'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.income_tax') + ' ' + '(%)', style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.income_tax_amount'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.other_detuction'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.net_amount'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.signature_date_&_stamp'), style: 'th', alignment: 'center' }
        ]
      ]
      trainees.filter((data, optionIndex) => {
        allRows.push(
          [
            { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? data.personal.name_bn : data.personal.name), style: 'td', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? data.designation_name_bn : data.designation_name), style: 'td', alignment: 'center' },
            { text: vm.$n(data.total_days), style: 'td', alignment: 'center' },
            { text: vm.$n(data.honour_amount), style: 'td', alignment: 'center' },
            { text: vm.$n(data.total_days * data.honour_amount), style: 'td', alignment: 'center' },
            { text: vm.$n(data.vat_tax), style: 'td', alignment: 'center' },
            { text: vm.$n(data.vat_tax_amount), style: 'td', alignment: 'center' },
            { text: vm.$n(data.other), style: 'td', alignment: 'center' },
            { text: vm.$n(data.payment_amount), style: 'td', alignment: 'center' },
            {}
          ]
        )
      })
      pdfContent.push({ text: '', style: 'tableMargin' })
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['5%', '9%', '10%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '20%'],
          body: allRows
        }
      })
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 0]
      },
      org: {
          fontSize: 8,
          bold: true,
          margin: [5, -25, 5, 5]
      },
      address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
      },
      hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
      },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        fertilizer: {
          fontSize: 10,
          margin: [10, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 0]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
